import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Truck({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M0.75 13.5V6C0.75 5.60218 0.908036 5.22064 1.18934 4.93934C1.47065 4.65804 1.85218 4.5 2.25 4.5H14.25C14.6478 4.5 15.0294 4.65804 15.3107 4.93934C15.592 5.22064 15.75 5.60218 15.75 6V12"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M15.75 12V6H18.75C19.9435 6 21.0881 6.47411 21.932 7.31802C22.7759 8.16193 23.25 9.30653 23.25 10.5V16.5C23.25 16.8978 23.092 17.2794 22.8107 17.5607C22.5294 17.842 22.1478 18 21.75 18H21"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M23.25 12H20.25C19.8522 12 19.4706 11.842 19.1893 11.5607C18.908 11.2794 18.75 10.8978 18.75 10.5V6"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M18.75 17.25C18.75 17.8467 18.5129 18.419 18.091 18.841C17.669 19.2629 17.0967 19.5 16.5 19.5C15.9033 19.5 15.331 19.2629 14.909 18.841C14.4871 18.419 14.25 17.8467 14.25 17.25C14.25 16.6533 14.4871 16.081 14.909 15.659C15.331 15.2371 15.9033 15 16.5 15C17.0967 15 17.669 15.2371 18.091 15.659C18.5129 16.081 18.75 16.6533 18.75 17.25V17.25Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M6.75 17.25C6.75 17.8467 6.51295 18.419 6.09099 18.841C5.66903 19.2629 5.09674 19.5 4.5 19.5C3.90326 19.5 3.33097 19.2629 2.90901 18.841C2.48705 18.419 2.25 17.8467 2.25 17.25C2.25 16.6533 2.48705 16.081 2.90901 15.659C3.33097 15.2371 3.90326 15 4.5 15C5.09674 15 5.66903 15.2371 6.09099 15.659C6.51295 16.081 6.75 16.6533 6.75 17.25Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle d="M12 18H9" stroke={color} strokeRound />
    </BaseSvgStyle>
  );
}

export default Truck;
